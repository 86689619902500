<template>
  <form
    method="post"
    class="relative"
    action="https://newsletter.infomaniak.com/v3/api/1/newsletters/webforms/19772/submit"
  >
    <input
      type="hidden"
      name="key"
      value="eyJpdiI6ImJKa1F4SFdicEI3YVBEMyt5cDJGd2lMeGxqdWpFYVVjUDJIK3BQWEVjQTQ9IiwibWFjIjoiOTZmNWYxNDBjOGIzOWZmNzVjYjg1ZThmNWVjNzExMjg4M2MzNmEyNGZkZTc1MDcyODEwYWFiZmRhNzhlNzcyOCIsInZhbHVlIjoiYnk5aDVxeEpWNTZoQTZzKzRCSytBSVg2TFgxZEo2RXVCVm9BeGFyb0pxQT0ifQ=="
    />
    <input type="hidden" name="webform_id" value="19772" />
    <input
      v-model="email"
      type="email"
      name="inf[1]"
      class="w-full rounded-none border-b bg-transparent py-2 font-agrandir-wide tracking-wider lg:text-xl"
      :class="{
        'border-white text-white placeholder:text-white': isDark,
        'border-black text-black placeholder:text-black': !isDark,
      }"
      data-inf-meta="1"
      data-inf-error=""
      :required="true"
      :placeholder="$t('footer.newsletterEmail')"
      :aria-label="$t('footer.newsletterEmail')"
      @focus="init"
    />
    <div class="my-2 bg-white" :class="{ hidden: !email }">
      <label
        id="mcaptcha__token-label"
        data-mcaptcha_url="https://captcha.infomaniak.com/widget/?sitekey=wKJaAigS1e48fWgqtjvg5w7rKA6QIwmy"
        for="mcaptcha__token"
      >
        <input id="mcaptcha__token" type="text" name="mcaptcha__token" />
      </label>
      <div id="mcaptcha__widget-container" />
    </div>
    <div class="-ml-1 mt-6 flex flex-col gap-4">
      <input
        type="submit"
        name=""
        class="m-[0.15rem] inline-flex w-max cursor-pointer items-center gap-5 whitespace-nowrap rounded border border-black bg-white p-3 text-center font-agrandir-wide text-sm font-medium uppercase tracking-max text-black transition-all duration-300 ease-in-out hover:m-0 hover:p-[0.9rem] md:mx-0 md:my-[0.13rem] md:px-6 md:py-[1.1rem] md:hover:-mx-1 md:hover:my-0 md:hover:px-7 md:hover:py-[1.23rem]"
        :value="$t('footer.newsletterSubscribe')"
      />
    </div>
  </form>
</template>

<script lang="ts" setup>
defineProps<{
  isDark: boolean
}>()
const email = ref('')

function init() {
  useHead({
    script: [
      {
        src: 'https://unpkg.com/@mcaptcha/vanilla-glue@0.1.0-rc2/dist/index.js',
      },
    ],
  })
}
</script>
